import { useAssistantSettingsStore } from "@/stores/assistantSettingsStore";

export default defineNuxtRouteMiddleware(() => {
  const { assistantSettings } = useAssistantSettingsStore();

  if (!assistantSettings.config.has_registration) {
    return navigateTo("/login");
  }

  return;
});
